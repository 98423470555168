.navcon {
    display: block;
    width: 100%;
    position: relative;
    
    body.index &, body.en-index & {
    	margin-bottom: rem($base-line-height);

	    @include breakpoint(small) {
	    	margin-bottom: 0;
	    }
    }

    @include breakpoint(large) {
	    position: fixed;
	    padding: rem($base-line-height) 0;
	    left: 0;
	    transition: top .6s ease;
	    top: 0;
	    z-index: 100;
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
    }

    @include breakpoint(giant) {
    	padding: rem($base-line-height * 1.5) 0;
    }

    body.cmsbackend &, body.nojs & {
    	@include breakpoint(large) {
    		position: absolute;
    	}
    }

    &:before {
    	@include breakpoint(large) {
    		content: "";
    		display: inline-block;
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 100%;
    		height: 100%;
    		opacity: 1;
    		background: $third;
    		transition: all .4s ease;
    	}
    }

    &.fixed {
        @include breakpoint(large) {
	        top: 0;
	        z-index: 100;
	        transition: all .6s ease;
	        padding: rem($base-line-height / 2) 0;

	        .navi {
	            transform: translateY(0);
	        }
        }

        @include breakpoint(giant) {
	    	padding: rem($base-line-height * 0.75) 0;
	    }

        &:before {
        	@include breakpoint(large) {
        		width: 100%;
    			opacity: 1;
    			box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.2);
        	}
        }

        .branding {
        	@include breakpoint(huge) {
        		max-width: rem(370px);
        	}
        }
    }

    > .row {
    	@include breakpoint(large) {
    		align-items: center;
    	}

    	&:first-child {
    		margin-bottom: rem($base-line-height);
    	}

    	> .col {
    		&:last-child {
    			@include breakpoint(large, max) {
    				display: none;
    			}
    		}
    	}
    }

    .branding {
    	backface-visibility: hidden;
    	margin-bottom: rem($base-gap * 2);
    	margin-top: rem($base-gap);

    	@include breakpoint(tiny) {
    		margin-top: 0;
    		position: absolute;
    		left: rem($base-gap);
    		top: rem($base-gap);
    	}

    	@include breakpoint(large) {
    		margin-bottom: 0;
    		display: block;
    		transition: all .3s ease;
    		left: 0;
    		top: 0;
    		max-width: 100%;
    	}

    	body:not(.index) & {
    		margin-bottom: 0;
    	}

    	img {
    		backface-visibility: hidden;
	        float: left;
	        transition: all .3s ease;
    	}
    }

	.navi {
	    display: none;
	    transition: margin-top .5s ease;

	    @include breakpoint(large) {
	        display: flex;
	        width: 92%;
	        justify-content: space-between;

	        &:not(.subnav) {
	        	z-index: 100;
	        	position: relative;
	        }
	    }

	    li {
	        text-align: center;
	        backface-visibility: hidden;

	    	a, span {
	            backface-visibility: hidden;
	            color: $dark;
	            font-family: $display-font;
	            font-weight: 400;
	            text-transform: uppercase;
	            position: relative;
				font-size: rem(14px);

				@include breakpoint(giant) {
					font-size: rem(16px);
				}
	    	}
	    }

	    & > li {
	        position: relative;
	        text-align: right;
	        padding: rem(8px) 0;

			> a {
				&.active {
					&:hover {
						color: $secondary;
					}
				}
			}

			&:nth-child(2) {
				> a {
					font-weight: 900;
				}
			}

	        a,
	        span {
	            transition: all 350ms ease-in-out;
	            color: $dark;
	            text-decoration: none;
	            display: inline-block;
	            width: 100%;
	            padding-right: 0;
	            padding-left: 0;
	            position: relative;
				font-size: rem(14px);
				position: relative;
				font-family: $display-font;
	            font-weight: 400;

				@include breakpoint(huge) {
					font-size: rem(16px);
				}
	        }

	        &:hover, &.active {
	        	color: $primary;

	        	> a, > span {
	        		color: $primary;
	        	}
	        }
	    }
	}

	.contact {
		display: none;

		@include breakpoint(large) {
			display: flex;
			color: $dark;
			justify-content: flex-end;
			flex-direction: column;
			font-size: rem(14px);
			font-family: $main-font;
			text-align: left;
		}

		@include breakpoint(giant) {
			font-size: rem(14px);
		}

		@include breakpoint(huge) {
			font-size: rem(18px);
		}

		&.right {
			@include breakpoint(large) {
				text-align: right;
			}
		}

		li {
			a {
				text-decoration: none;
				color: $dark;

				&:hover {
					color: $primary;
				}
			}
		}
	}

	> .row {
		> div {
			&:first-child {
				display: flex;
				align-items: center;
				justify-content: center;

				@include breakpoint(small) {
					justify-content: flex-start;
					margin-top: rem($base-gap);
				}

				@include breakpoint(large) {
					margin-top: 0;
				}
			}
		}
	}
}
/////////////////////////////////////////////////////////////////////////////////////

// Mobile Menu Bar
//////////////////////////////
nav {
	&.mobil {
	    width: 100%;
	    text-align: left;
	    display: inline-block;
	    overflow: scroll;

	    @include breakpoint(small) {
	    	display: flex;
    		position: relative;
    		flex-direction: row-reverse;
	    }

	    .navi {
	    	text-align: center;

	    	@include breakpoint(small) {
	    		text-align: left;
	    	}

	    	@include breakpoint(small, max) {
	    		margin-bottom: rem($base-gap * 2);
	    	}

			li {
				&:last-of-type {
					padding-bottom: 2rem;
				}
			}
	    

			> li {
				a, span {
					color:$light;
					font-weight: 400;
					font-size: rem(16px);
					display: inline-block;
					width: 100%;
					text-align: center;
					transition:.35s all ease;
					text-decoration: none;
					padding: rem(13px) 0;
					background-color: transparent;

					@include breakpoint(small) {
						font-size: rem(16px);
						text-align: left;
						padding-left: rem($base-gap);
					}
				
					&:hover {
						color:$secondary;

						&:after {
							color:$secondary;
						}
					}

					&.active {
						color:$secondary;
						font-weight: 700;

						&:after {
							color:$secondary;
						}
					}
				}

				&:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
					a, span {
						font-size: rem(30px);
						line-height: rem(30px);
						font-family: $display-font;
					}
				}

				&:nth-child(9) {
					a {
						padding-bottom: rem(40px);
						margin-bottom: rem(20px);
						position: relative;

						&:after {
							content: "";
							display: inline-block;
							position: absolute;
							bottom: 0;
							left: rem($base-gap);
							right: rem($base-gap);
							border-bottom: rem(1px) solid $secondary;
						}
					}
				}

				&.active {
					> a {
						&:after {
							color:$secondary;
						}
					}
				}
			}
		}
	}
}


.page-navi {
	// Off Canvas Navigation
	background: $primary;
	left:0;
	overflow-y: auto;
	position: fixed;
	transition:.6s all ease-in-out;
	width: 100%;
	z-index: 999;
	top: rem(60px);
	max-width:100%;
	bottom: 0;
	transform:translate3d(-100%,0,0);
	visibility: hidden;

	@include breakpoint(small) {
		display: flex;
	}

	.nojs & {
		transform:translate3d(-100%,0,0);
		transition:none;
	}

	@include breakpoint(large) {
		display: none;
	}

	&::-webkit-scrollbar {
	    width: rem(8px);
	    background-color: $secondary;
	}
	   
	&::-webkit-scrollbar-thumb {
		background-color: $primary;
		outline: none;
	}

	.navi {
		@include breakpoint(small) {
			width: 45%;
			overflow-Y: scroll;
			position: relative;
			display: inline-block;
			max-height: 100%;
			margin-right: 5%;
			padding: rem($base-line-height * 1.5) 0;
		}

		@include breakpoint(medium) {
			width: 35%;
			margin-right: 5%;
			padding: rem($base-line-height * 2) 0;
		}
	}
}

.mobile-bar {
	align-items:center;
	background: $light;
	display: flex;
	position: fixed;
	right: rem($base-gap / 2);
	top: rem($base-gap / 2);
	border: rem(1px) solid $medium;
	z-index: 894;
	height: 60px;
    z-index: 105;
    width: 120px;
    border-radius: 40px;
    transition: all .6s ease;
    box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.15);

    @include breakpoint(tiny) {
    	top: rem(32px);
    	right: rem($base-gap);
    }

    @include breakpoint(small) {
    	right: rem(32px);
    }

	@include breakpoint(large) {
		display: none;
	}

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);

		&:first-child {
			width: 0;
			overflow: hidden;
			transition: width .6s ease, opacity .8s .6s ease;
			padding: 0;
			opacity: 0;

			span {
				margin-top: rem(5px);
				transform: translateX(-100px);
				width: 0;
				overflow: hidden;
				transition: all .6s ease;
				color: $dark;
				font-size: rem(14px);
			}
		}

		&:last-child {
        	justify-content:flex-end; 
        	color: $dark; 

        	span {
        		&:first-child {
        			margin-right: rem(34px);
        		}
        	}
        }
	}

}

label[for="navi-toggled"] {
	flex-direction:row-reverse;
	display: inline-block;
	display: flex;
    position: relative;
    height: rem(22px);
    width: rem(88px);
    @include breakpoint(large) {
    	display: none;
    }
    
	.cd-nav-icon {
		width: rem(23px);
    	height: rem(2px);
		background-color: $dark;
		transition: all 0.5s 300ms;
		overflow: visible;
		position: absolute;
    	top: 50%;
    	right:0;
		display: inline-block;


		&::before, &:after {
			/* upper and lower lines of the menu icon */
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: rem(23px);
			height: rem(2px);
			background-color: inherit;
			background-color: $dark;
			transform: translateZ(0);
			backface-visibility: hidden;
			transition: 0.5s all ease-in-out 300ms;
			
		}

		&::before {
			transform-origin: right top;
			transform: translateY(-6px);
		}

		&::after {
			transform-origin: right bottom;
			transform: translateY(6px);
		}
	}
}

#navi-toggled {
	display: none;

	&:checked {
		~ .mobile-bar {
			top: 0;
			right: 0;
			width: 100%;
			border-radius: 0;
			border-color: $light;
			background: $light;
			border-bottom-color: $light;

			li {
				&:first-child {
					width: auto;
					padding:0 rem($base-gap);
					opacity: 1;

					span {
						transform: translateX(0);
						width: auto;
					}
				}
			}
		}

		/* NAVIICON */
		~ * label[for="navi-toggled"] {

			.mobile-bar {
				top: 0;
				right: 0;
				width: 100%;
			}
			
			.cd-nav-icon {
				background-color: transparent;
				&:after, 
				&:before {
					width:100%;
					transition: all 0.5s 300ms;
					@include breakpoint(large) {
						width: rem(28px);
					}
				}

				&:before {
					transform: rotate(45deg);
					transform-origin: center bottom;
				}

				&:after {
					transform: rotate(-45deg);
					transform-origin: center top;
				}
			}

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}
		~ * .branding {
			z-index: -1;
		}
		/* NAVIBLOCK */
		~ * .page-navi,
		~ .page-navi {
			transform:translate3d(0,0,0);
			visibility: visible;
		}
	}
}

.nav-galerie {
	display: flex;
	flex-wrap: wrap;
	padding: rem($base-gap*2) rem($base-gap);
	align-items: center;
	background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
	margin-bottom: rem($base-line-height);
	color: $light;

	@include breakpoint(small) {
		width: 50%;
		min-height: 100%;
		position: relative;
		overflow-Y: scroll;
		margin-bottom: 0;
	}

	@include breakpoint(medium) {
		padding: rem($base-gap*2) 2.5%;
	}

	div {
		width: 100%;
		position: relative;
		z-index: 1;
		text-align: center;

		@include breakpoint(small) {
			width: 100%;
		}

		dl {
			display: inline-block;
			width: 100%;

			dt, dd {
				background: none;
				padding: 0;
			}

			dt {
				font-weight: 700;

				&.last {
					margin-top: rem($base-line-height);
				}
			}
		}

		.h1, .h2 {
			color: $light;
		}

		.btn {
			&.phone {
				text-decoration: none;
			}
		}
	}
}

// FOOTER NAVI
footer {
	.navi-add {
		display: none;
		position: relative;
		width: 100%;
		font-size: rem(16px);

		@include breakpoint(large) {
			display: flex;
			justify-content: space-between;
			margin-bottom: rem($base-line-height);
			margin-top: rem($base-line-height);
		}

		@include breakpoint(giant) {
			font-size: rem(16px);
		}

		li:not(.hasSub) {
			@include breakpoint(large) {
                position: relative;
                backface-visibility: hidden;
			}

            &:hover {
                > a,
                > span {
                    color: $primary;
                }
            }

            &.active {
            	> a,
                > span {
                    color: $primary;
                }
            }

			a {
				color: $dark;
				text-decoration: none;
				transition: color 500ms ease;
                z-index: 2;
                position: relative;
                backface-visibility: hidden;
                font-size: rem(16px);
                display: inline-block;
                position: relative;
                font-family: $main-font;

                @include breakpoint(giant) {
                	font-size: rem(18px);
                }

                &:hover {
	            	> a,
	                > span {
	                    color: $primary;
	                }
	            }
			}
		}
	}
}