// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
    $name: "icomoon",
    $file: "icomoon-webfont",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Butler",
    $file: "butler_regular-webfont",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Butler",
    $file: "butler_black-webfont",
    $weight: 900,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Fjalla One",
    $file: "fjalla-one-v5-latin-regular",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Barlow",
    $file: "barlow-v3-latin-regular",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Barlow",
    $file: "barlow-v3-latin-700",
    $weight: 700,
    $style: normal,
    $type: woff
);