/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    button {
        display: inline-block;
        border: rem(1px) solid $secondary;
        width: rem(35px);
        height: rem(35px);
        background: $secondary;
        color: $light;
        z-index: 1;
        outline: none;
        background: transparent;
        color: transparent;
        font-size: 0;

        @include breakpoint(handy) {
            bottom: rem(10px);
        }

        @include breakpoint(giant) {
            width: rem(45px);
            height: rem(45px);
        }

        i {
            font-size: rem(34px);
        }

        &.slick-prev, &.slick-next {
            position: absolute;
            bottom: 0;
            transition: all 350ms ease;

            @include breakpoint(handy) {
                bottom: rem(10px);
            }

            &:hover {
                background: darken($secondary, 10%);
            }
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    right: calc(50% + 9px);
    z-index: 2;

    &:before {
        @extend .icon-default;
        @extend .icon-line-left;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        color: $dark;
    }

    @include breakpoint(large) {
        left: rem($base-gap);
    }

    @include breakpoint(huge) {
        left: rem($base-gap * 4);
    }
}

.slick-next {
    right: auto;
    left: calc(50% + 9px);
    z-index: 2;

    &:before {
        @extend .icon-default;
        @extend .icon-line-right;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        color: $dark;
    }

    @include breakpoint(large) {
        right: rem($base-gap);
        left: auto;
    }

    @include breakpoint(huge) {
        right: rem($base-gap * 4);
    }
}

.slick-prev, .slick-next {
    width: rem(38px);
    height: rem(38px);
    border: rem(2px) solid $dark;
    outline: none;
    bottom: 0;
    transform: translateY(-50%);
    background: transparent;
    color: transparent;
    font-size: 0;

    @include breakpoint(handy) {
        bottom: rem(10px);
    }

    @include breakpoint(large) {
        top: 49%;
    }

    &:hover {
        outline: none;
        background: transparent;

        &:before {
            color: $light;
        }
    }

    &:before {
        color:$dark;
        font-size: rem(20px);
        transition: all .6s ease;
    }
}