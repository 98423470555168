// PAGE WRAP
//////////////////////////////

.page-wrap {
	overflow: hidden;
}

// HEADER
//////////////////////////////

.header {
	position: relative;
	width: 100%;
	padding: rem(74px) 0 rem(84px);
	width: 100%;
	color: $dark;
	text-align: center;
	z-index: 10;
	display: inline-block;

	@include breakpoint(tiny) {
		padding: 0 0 rem(124px);
	}

	@include breakpoint(small) {
		padding: 0 0 rem(144px);
	}

	body:not(.cmsbackend) & {
		z-index: 10;
	}

	body:not(.index):not(.en-index) & {
		padding-top: rem(80px);
		padding-bottom: rem(46px);

		@include breakpoint(tiny) {
			padding: 0 0 rem(64px);
		}

		@include breakpoint(small) {
			padding: 0 0 rem(64px);
		}

		@include breakpoint(large) {
			padding-top: rem(190px);
			padding-bottom: rem(70px);
		}

		@include breakpoint(giant) {
			padding-top: rem(230px);
		}

		@include breakpoint(huge) {
			padding-top: rem(270px);
		}
	}

	body.index &, body.en-index & {
		@include breakpoint(large) {
			padding-top: rem(320px);
			padding-bottom: rem(70px);
		}

		@include breakpoint(giant) {
			padding-top: rem(380px);
		}

		@include breakpoint(huge) {
			padding-top: rem(430px);
		}
	}

	.branding {
		display: inline-block;
		max-width: rem(300px);
		z-index: 101;

		@include breakpoint(tiny) {
			max-width: rem(300px);
		}

		@include breakpoint(large) {
			max-width: rem(240px);
			position: relative;
		}

		@include breakpoint(giant) {
			max-width: rem(290px);
		}

		@include breakpoint(huge) {
			max-width: rem(370px);
		}

		@include breakpoint(final) {
			max-width: 100%;
		}

		img {
			body.iexplore & {
				display: inline-block;
				width: 100% !important;
			}
		}
	}

	.slider {
		position: absolute;
		bottom: 0;
		overflow: hidden;
		left: 0;
		width: 100%;
		top: rem(200px);

		@include breakpoint(tiny) {
			top: rem(120px);
		}

		@include breakpoint(large) {
			top: rem(180px);
		}

		@include breakpoint(giant) {
			top: rem(220px);
		}

		@include breakpoint(huge) {
			top: rem(240px);
		}

		.slick-list {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;

		    .slick-track {
			    position: absolute;
			    height: 100%;

			    .slick-slide {
			    	overflow: hidden;

			    	&:before {
			    		content: "";
			    		display: inline-block;
			    		position: absolute;
			    		width: 100%;
			    		height: 100%;
			    		left: 0;
			    		top: 0;
			    		background: linear-gradient(to bottom, rgba(255,255,255,.7) 0%,rgba(255,255,255,0.5) 30%,rgba(255,255,255,0.5) 100%);
			    		z-index: 1;
			    	}

			    	img {
			    		position: absolute;
			    		width: auto;
			    		height: auto;
			    		min-height: 100%;
			    		min-width: 100%;
			    		max-width: none;
			    		left: 50%;
			    		top: 50%;
			    		transform: translate(-50%, -50%);
			    	}
			    }
			}
		}
	}

	> .row {
		text-align: center;

		@include breakpoint(tiny) {
			margin-top: rem(240px);
		}

		@include breakpoint(large) {
			margin-top: 0;
		}

		body:not(.index):not(.en-index) & {
			@include breakpoint(tiny) {
				margin-top: rem(110px);
			}

			@include breakpoint(large) {
				margin-top: 0;
			}
		}

		.claim {
			display: block;
			font-family: $display-font;
			font-size: rem(22px);
			margin-bottom: rem($base-line-height);
			position: relative;
			color: $dark;
			text-transform: uppercase;
			font-weight: 900;
			text-align: center;

			@include breakpoint(tiny) {
				font-size: rem(26px);
				line-height: rem(34px);
			}

			@include breakpoint(small) {
				font-size: rem(32px);
				line-height: rem(38px);
			}

			@include breakpoint(medium) {
				font-size: rem(40px);
				line-height: rem(50px);
				margin-bottom: rem($base-line-height * 1.5);
			}

			@include breakpoint(large) {
				font-size: rem(50px);
				line-height: rem(56px);
				margin-bottom: rem($base-line-height * 2);
			}

			@include breakpoint(huge) {
				font-size: rem(68px);
				line-height: rem(72px);
			}

			span {
				font-size: rem(15px);
				line-height: rem(24px);
				color: $primary;
				font-weight: 400;
				font-family: $sub-display-font;
				display: block;
				position: relative;
				letter-spacing: rem(1px);

				@include breakpoint(handy) {
					font-size: rem(17px);
				}

				@include breakpoint(tiny) {
					font-size: rem(20px);
				}

				@include breakpoint(small) {
					letter-spacing: rem(2px);
				}

				@include breakpoint(medium) {
					letter-spacing: rem(3px);
				}

				@include breakpoint(giant) {
					font-size: rem(24px);
					line-height: rem(30px);
				}

				@include breakpoint(huge) {
					font-size: rem(28px);
					line-height: rem(34px);
					letter-spacing: rem(5px);
				}
			}
		}

		.btn {
			&:not(:last-child) {
				@include breakpoint(small) {
					margin-right: rem(10px);
				}

				@include breakpoint(giant) {
					margin-right: rem($base-gap);
				}

				@include breakpoint(huge) {
					margin-right: rem(28px);
				}
			}

			&.red {
				&:hover {
					background: darken($secondary, 10%);
					color: $light;
				}
			}

			&:not(.red) {
				&:hover {
					background: darken($primary, 10%);
					color: $light;
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	ul, dl {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	body:not(.index):not(.en-index) & {
		@include breakpoint(large) {
			padding-top: rem($base-line-height);
		}

		@include breakpoint(huge) {
			padding-top: rem($base-line-height * 3);
		}
	}

	body.anfahrt &, body.en-arrival & {
		@include breakpoint(medium) {
			padding: 0 0 rem($base-gap * 2);
		}

		@include breakpoint(large) {
			padding: 0 0 rem(42px);
		}

		@include breakpoint(giant) {
			padding: 0 0 rem(62px);
		}

		@include breakpoint(huge) {
			padding: 0 0 rem(70px);
		}
	}

	.info {
		padding: rem($base-gap * 2) 0;
		background: $primary;
		margin: 0 0 rem($base-gap * 3);
		color: $light;

		@include breakpoint(tiny) {
			padding: rem($base-gap * 3) 0;
			margin: 0 0 rem($base-gap * 4);
		}

		@include breakpoint(medium) {
			padding: rem($base-gap * 4) 0;
			margin: 0 0 rem($base-gap * 5);
		}

		@include breakpoint(large) {
			padding: rem(82px) 0;
			margin: 0 0 rem(100px);
		}

		@include breakpoint(giant) {
			padding: rem(112px) 0;
			margin: 0 0 rem(130px);
		}

		@include breakpoint(huge) {
			padding: rem(140px) 0;
			margin: 0 0 rem(148px);
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			font-family: $display-font;
			text-transform: uppercase;
			margin-bottom: 0;

			@include breakpoint(final) {
				justify-content: space-between;
			}

			li {
				padding-left: rem(25px);
				margin-bottom: rem(15px);
				width: 100%;

				&:first-child, &:nth-child(3) {
					@include breakpoint(medium) {
						width: 40%;
					}

					@include breakpoint(final) {
						width: auto;
					}
				}

				&:last-child, &:nth-child(2) {
					@include breakpoint(medium) {
						width: 60%;
					}

					@include breakpoint(final) {
						width: auto;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					text-indent: rem(-25px);
				}
			}
		}
	}

	.aktuelleangebote {
		.headline {
			@extend .h3;
			margin-bottom: 0;
		}

		> .col {
			&:first-child {
				margin-bottom: rem($base-line-height * 2);

				@include breakpoint(medium) {
					padding-right: rem($base-gap * 2);
				}

				@include breakpoint(large) {
					padding-right: rem($base-gap * 3);
				}

				@include breakpoint(giant) {
					margin-bottom: 0;
				}
			}

			&:nth-child(2) {
				margin-bottom: rem($base-line-height * 2);

				@include breakpoint(medium) {
					padding-right: rem($base-gap * 2);
				}

				@include breakpoint(large) {
					padding-right: rem($base-gap * 3);
				}

				@include breakpoint(giant) {
					margin-bottom: 0;
				}
			}

			&:last-child {
				@include breakpoint(giant) {
					text-align: right;
				}
			}
		}

		.beschreibung {
			@include breakpoint(large) {
				margin-bottom: rem($base-line-height * 2);
			}
		}
	}

	.mbottom {
		margin-bottom: rem($base-gap);

		@include breakpoint(small) {
			margin-bottom: rem($base-line-height);
		}

		@include breakpoint(giant) {
			margin-bottom: rem($base-line-height * 1.5);
		}
	}

	.contentBox {
		background: $light;
		padding: rem($base-line-height) rem($base-gap);

		@include breakpoint(giant) {
			padding: rem($base-line-height * 2) rem($base-line-height);
		}

		@include breakpoint(huge) {
			padding: rem($base-line-height * 2);
		}
	}

	.teaser {
		padding: rem($base-gap * 2) 0;
		margin: rem($base-gap * 3) 0;
		background: $primary;

		@include breakpoint(tiny) {
			padding: rem($base-gap * 3) 0;
			margin: rem($base-gap * 4) 0;
		}

		@include breakpoint(medium) {
			padding: rem($base-gap * 4) 0;
			margin: rem($base-gap * 5) 0;
		}

		@include breakpoint(large) {
			padding: rem(82px) 0;
			margin: rem(100px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(112px) 0;
			margin: rem(130px) 0;
		}

		@include breakpoint(huge) {
			padding: rem(140px) 0;
			margin: rem(148px) 0;
		}

		img {
			margin-bottom: rem($base-line-height);
		}
	}

	.teaser-2 {
		padding: rem($base-gap * 3) 0 0;
		width: 100%;
		z-index: 1;
		position: relative;
		margin: rem($base-gap * 3) 0;

		@include breakpoint(tiny) {
			padding: rem($base-gap * 4) 0 0;
			margin: rem($base-gap * 4) 0;
		}

		@include breakpoint(medium) {
			padding: 0;
			margin: rem($base-gap * 4) 0;
		}

		@include breakpoint(large) {
			margin: rem(90px) 0;
		}

		@include breakpoint(giant) {
			margin: rem(110px) 0;
		}

		@include breakpoint(huge) {
			margin: rem(130px) 0;
		}

		.siegel {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			max-width: rem(400px);
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			transform: translate(-8px, -50%);
			align-items: baseline;

			@include breakpoint(giant) {
				transform: translate(20px, -50%);
			}

			img {
				max-width: calc(50% - 18px);
				height: auto;

				&:last-child {
					margin-left: rem($base-gap);
				}
			}
		}

		a {
			text-decoration: none;
			color: $dark;
			position: relative;

			&:hover {
				.img {
					img {
						transform: scale(1.1);
					}
				}

				.btn {
					background: $light;
					color: $dark!important;
				}
			}
		}

		> .row {
			> .col {
				&:first-child {
					body.reservierung & {
						opacity: .4;

						@include breakpoint(medium, max) {
							display: none;
						}
					}
				}
				&:last-child {
					body.index & {
						@include breakpoint(medium, max) {
							margin-top: rem(102px);
						}
					}
					body.zimmer & {
						@include breakpoint(medium, max) {
							display: none;
						}
					}
				}
			}
		}

		> .row {
			> .col {
				&:first-child {
					.row {
						background: $secondary;
						padding: rem($base-gap * 3) 0;
						margin-bottom: rem($base-gap * 3);
						color: $light;

						@include breakpoint(large) {
							padding: rem(90px) 0;
						}

						&:before {
							@include breakpoint(handy) {
								content: "";
								display: inline-block;
								position: absolute;
								right: 100%;
								top: 0;
								height: 100%;
								width: 100%;
								background: $secondary;
							}
						}

						&:after {
							@include breakpoint(handy) {
								@include breakpoint(medium, max) {
									content: "";
									display: inline-block;
									position: absolute;
									left: 100%;
									top: 0;
									height: 100%;
									width: 100%;
									background: $secondary;
								}
							}
						}

						.btn {
							border-color: $light;
							color: $dark;
							background: $light;

							&:hover {
								background: $third;
								color: $dark;
								border-color: $dark;
							}
						}

						.h2 {
							color: $light;

							span {
								color: $third;
							}
						}
					}
				}

				&:last-child {
					.row {
						@include breakpoint(medium) {
							padding: rem($base-gap * 3) 0;
							text-align: right;
						}

						@include breakpoint(large) {
							padding: rem(90px) 0;
						}
					}

					.btn {
						@include breakpoint(medium) {
							float: right;
						}
					}
				}
			}
		}

		.img {
			position: relative;
			margin-bottom: rem($base-line-height);
			overflow: hidden;

			@include breakpoint(large) {
				margin-bottom: rem($base-line-height * 1.5);
			}

			@include breakpoint(huge) {
				margin-bottom: rem($base-line-height * 2);
			}

			img {
				transition: all .6s ease;
			}

			div {
				position: absolute;
				flex-direction: column;
				align-items: flex-start;
				width: calc(100% - 36px);
				bottom: rem($base-gap);
				left: rem($base-gap);

				@include breakpoint(huge) {
					width: calc(100% - 100px);
					bottom: rem(50px);
					left: rem(50px);
				}

				span {
					background: $light;
					padding: rem(8px);
					display: inline-block;

					@include breakpoint(small) {
						font-size: rem(24px);
						line-height: rem(28px);
					}

					@include breakpoint(medium) {
						font-size: rem(20px);
						line-height: rem(26px);
					}

					@include breakpoint(giant) {
						font-size: rem(22px);
						line-height: rem(28px);
						padding: rem(8px) rem(12px);
					}

					@include breakpoint(huge) {
						font-size: rem(26px);
						line-height: rem(32px);
					}

					&:first-child {
						margin-bottom: rem(6px);
						font-family: $display-font;

						@include breakpoint(small) {
							font-size: rem(22px);
							line-height: rem(30px);
						}

						@include breakpoint(medium) {
							font-size: rem(18px);
							line-height: rem(26px);
						}

						@include breakpoint(giant) {
							font-size: rem(20px);
						}

						@include breakpoint(huge) {
							font-size: rem(25px);
							line-height: rem(30px);
						}
					}
				}
			}
		}
	}

	.teaser-3 {
		padding: rem($base-gap * 2) 0;
		margin: rem($base-gap * 3) 0;
		background: $primary;
		color: $light;

		@include breakpoint(tiny) {
			padding: rem($base-gap * 3) 0;
			margin: rem($base-gap * 4) 0;
		}

		@include breakpoint(medium) {
			padding: rem($base-gap * 4) 0;
			margin: rem($base-gap * 5) 0;
		}

		@include breakpoint(large) {
			padding: rem(82px) 0;
			margin: rem(100px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(112px) 0;
			margin: rem(130px) 0;
		}

		@include breakpoint(huge) {
			padding: rem(140px) 0;
			margin: rem(148px) 0;
		}

		.iefix {
			body.iexplore &, body.safari & {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
			}
		}

		.auslesen {
			@extend .col;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column-reverse;

			@include breakpoint(small) {
				@extend .small-6;
			}

			@include breakpoint(giant) {
				@extend .giant-4;
			}

			body.iexplore &, body.safari & {
				flex-direction: row-reverse;
			}

			img {
				margin-bottom: rem($base-line-height);

				@include breakpoint(medium) {
					margin-bottom: rem($base-line-height * 1.5);
				}

				@include breakpoint(giant) {
					margin-bottom: rem($base-line-height * 2);
				}

				&:not(:first-of-type) {
					display: none;
				}
			}

			.col {
				width: 100%;
				flex-basis: 100%;
				max-width: 100%;
				padding: 0;

				&:first-child {
					body.iexplore &, body.safari & {
						order: 1;
					}
				}

				&:last-child {
					> .row {
						display: none;
					}
				}
			}

			.trim {
				margin-bottom: rem($base-line-height);
			}
		}

		.btn {
			border-color: $light;
			color: $dark;
			background: $light;
			margin-top: rem($base-line-height);

			@include breakpoint(medium) {
				margin-top: rem($base-line-height * 1.5);
			}

			@include breakpoint(large) {
				margin-top: rem($base-line-height * 2);
			}

			&:hover {
				background: $third;
				color: $dark;
				border-color: $dark;
			}
		}

		.h1 {
			color: $light;

			span {
				color: $third;
			}
		}

		h2 {
			@extend .h3;
			color: $light;
		}
	}

	.teaser-4 {
		margin: 0 auto rem($base-gap * 3);

		@include breakpoint(tiny) {
			margin: 0 auto rem($base-gap * 4);
		}

		@include breakpoint(medium) {
			margin: 0 auto rem($base-gap * 5);
		}

		@include breakpoint(large) {
			margin: 0 auto rem(100px);
		}

		@include breakpoint(giant) {
			margin: 0 auto rem(130px);
		}

		@include breakpoint(huge) {
			margin: 0 auto rem(148px);
		}
	}

	iframe.bewertung {
	    height: rem(175px);
	    width: rem(160px);
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	padding-bottom: rem($base-gap * 3);

	@include breakpoint(tiny) {
		padding-bottom: rem($base-gap * 4);
	}

	@include breakpoint(medium) {
		padding-bottom: rem($base-gap * 5);
	}

	@include breakpoint(large) {
		padding-bottom: .5rem;
	}

	@include breakpoint(giant) {
		font-size: rem(18px);
		line-height: rem(28px);
		padding-bottom: 2.5rem;
	}

	.map {
		width: 100%;
		margin: 0 0 rem($base-gap * 3);

		@include breakpoint(tiny) {
			margin: 0 0 rem($base-gap * 4);
		}

		@include breakpoint(medium) {
			margin: 0 0 rem($base-gap * 5);
		}

		@include breakpoint(large) {
			margin: 0 0 rem(100px);
		}

		@include breakpoint(giant) {
			margin: 0 0 rem(130px);
		}

		@include breakpoint(huge) {
			margin: 0 0 rem(148px);
		}

		iframe {
			width: 100%;
			height: rem(300px);

			@include breakpoint(medium) {
				height: rem(400px);
			}

			@include breakpoint(giant) {
				height: rem(504px);
			}
		}
	}

	span, strong {
		display: block;
	}

	img {
		margin-bottom: rem($base-line-height * 2);
	}

	dl {
		margin-bottom: 0;

		dt {
			font-weight: 700;

			@include breakpoint(medium) {
				float: left;
				width: 30%;
			}

			@include breakpoint(large) {
				width: 24%;
			}

			@include breakpoint(giant) {
				width: 27%;
			}

			@include breakpoint(huge) {
				width: 22%;
			}
		}

		dd {
			@include breakpoint(medium) {
				float: left;
				width: 70%;
			}

			@include breakpoint(large) {
				width: 76%;
			}

			@include breakpoint(giant) {
				width: 73%;
			}

			@include breakpoint(huge) {
				width: 78%;
			}
		}
	}

	.headline {
		display: block;
		font-family: $sub-display-font;
		font-size: rem(18px);
		margin-bottom: rem($base-line-height / 2);
		position: relative;
		font-weight: 400;
		letter-spacing: rem(1px);
		display: block;
		text-transform: uppercase;

		@include breakpoint(tiny) {
			font-size: rem(20px);
			line-height: rem(26px);
		}

		@include breakpoint(small) {
			letter-spacing: rem(2px);
		}

		@include breakpoint(medium) {
			letter-spacing: rem(3px);
			margin-bottom: rem($base-line-height);
		}

		@include breakpoint(giant) {
			font-size: rem(24px);
			line-height: rem(32px);
		}

		@include breakpoint(huge) {
			letter-spacing: rem(4px);
		}
	}

	> .row {
		> .col {
			@include breakpoint(medium, max) {
				text-align: center;
			}

			&:nth-child(3), &:nth-child(4) {
				@include breakpoint(medium, max) {
					margin-top: rem($base-line-height * 1.5);
				}
			}
		}
	}
}



// COOKIE MELDUNG
.acceptCookie {
    background-color:rgba(black, .85);
    color: $light;
    padding:.75rem 1.125rem;
    text-align:center;
    position: fixed;
    z-index: 30;
    width: 100%;
    bottom: 0;
    left: 0;
    
    a {
        color:inherit;
        &[href*=accepted] {
            float:right;
            text-decoration:none;
        }
    }
}
.teaserinfo {
	display: block;
	padding-left: 1rem !important;
	padding-right: 1rem !important;
	.row{
		justify-content:space-between;
		img{
			margin-bottom: 12px;
		}
	}
	.headline {
	font-family: "Fjalla One",sans-serif;
	font-weight: 400;
	font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 55%;
	float: left;
	display: inline-block;
	}
	.teaserimg img{
		width: auto;
		height: 120px;
		float: right;
	}
	a.btn{
		margin-top: 22px;
		margin-left: -17px;
		display: flex;
		justify-content: center;
		width: 223px;
		b{
			margin-left: 3px;
		}
	}
}
.bewertungsbox{
	text-align: center;
	@include breakpoint (medium) {
		text-align: left !important;
	}
	@include breakpoint (huge) {
		text-align: right !important;
	}	
}
.noGutterR{
	padding-right: 0px !important;
}
.noGutterL{
	padding-left: 0px !important;
}

.angebotsteaser{

	display: block;
    font-family: "Fjalla One",sans-serif;
    font-size: 1.125rem;
    margin-bottom: 1.625rem;
    position: relative;
    font-weight: 400;
    letter-spacing: .0625rem;
    text-transform: uppercase;
    margin-top: 40px;
}
.db{
    display: block;
    font-style: normal;
    font-family: "Barlow",sans-serif ;
}

.sliderBox {
	padding:rem(80px) rem($base-gap); 
	background:$primary;
	overflow:hidden;
	width:100%;

	.row{
		display:block;
	}

	@include breakpoint(medium) {
		padding:rem(100px) rem($base-gap) rem(120px) rem($base-gap); 
	}
	@include breakpoint(large) {
		padding:rem(120px) rem($base-gap) rem(140px) rem($base-gap); 
	}
	@include breakpoint(giant) {
		padding:rem(120px) rem($base-gap) rem(160px) rem($base-gap); 
	}
	@include breakpoint(huge) {
		padding:rem(150px) rem($base-gap) rem(230px) rem($base-gap); 
	}

	.usp{
		
		margin-bottom:rem(30px);
		span{
			display:inline-block;
			position:relative;
			padding-right:rem(8px);
			font-style:italic;
			text-align:center;
			font-size:rem(16px);
			
			&:after {
				content:'.';
				position:absolute;
				right:rem(0);
				bottom:0;
			}
			&:nth-child(3) {
				padding-right:0;
				&:after {
					content:none;
				}
			}
			
		}
		strong{
			display:block;
			font-size: rem(25px);
			margin: rem(20px) 0 0 0;
			position:relative;
			font-family:$display-font;
			text-align:center;

			&:before {
				content:'';
				position:absolute;
				top:rem(-10px);
				left:0;
				background:$secondary;
				height:rem(1px);
				width:100%;
			}
		}

		@include breakpoint(tiny) {
			span{
				font-size:rem(18px);
			}
		}

		@include breakpoint(medium) {
			margin-bottom:rem(50px);
			span{
				text-align:left;
				font-size:rem(25px);
				padding-right:rem(15px);
			}

			strong {
				font-size: rem(35px);
				margin: rem(30px) 0 0 0;
				&:before {
					top:rem(-15px);
					right:0;
					width:70%;
					left:auto;
				}
			}
		}
		@include breakpoint(large) {
			margin-bottom:rem(80px);
			span{
				font-size:rem(30px);
				padding-right:rem(20px);
				&:after {
					right:rem(2px);
				}
			}

			strong {
				font-size: rem(45px);
				line-height:rem(45px);
				margin: rem(40px) 0 0 0;
				&:before {
					top:rem(-20px);
					right:0;
					width:70%;
				}
			}
		}
		@include breakpoint(giant) {
			margin-bottom:rem(90px);
			span{
				font-size:rem(40px);
				padding-right:rem(20px);
				&:after {
					right:rem(2px);
				}
			}

			strong {
				font-size: rem(55px);
				margin: rem(56px) 0 0 0;
				line-height:rem(55px);
				&:before {
					top:rem(-28px);
					right:0;
					width:70%;

				}
			}
		}
	}

	.swiper-container {
		@include breakpoint(large) {
			width:80%;
			margin:0 auto;
		}
		@include breakpoint(huge) {
			width:90%;
			margin:0 auto;
		}

	}

	.headerSwiper {
		.swiper-wrapper {
			.swiper-slide {
				width:auto;
				height:rem(350px);
				@include breakpoint(large) {
						height:450px;
					}
					@include breakpoint(giant) {
						height:550px;
					}
					@include breakpoint(huge) {
						height:570px;
					}
				// height: calc(100vh - 70px);
				> div {
					position: relative;
					display: flex;

					

					&.swiper-lazy {
						background-position:center center;
						background-repeat: no-repeat;
						background-size:cover;
						object-fit: contain; 
						min-width: 0;
						height: 100%;
						max-width: 100%; 
					}
				}
			}
		}
	}

	body.nojs & {
		display:none;
	}
}

.swiper-container{
	position: absolute;
}
.swiper-slide{
	position: relative;
	width: 100%;
	height:auto;
}

.clearfix{
	.handy-11{
		p{
			margin-top: 1.625rem;
		}
	}

	.row{
		.tiny-6{
			a.lightbox-image{
				margin-bottom: 4.625rem;
			}
		}
	}
	.giant-10{
		.contentBox2{
			.row{
				.default-6{
					.fluid{
						height: 125px;
					}
				}
			}
		}
	}
}

#en-rooms{
	.clearfix {
		.row {
				height: 149px px;
			}
	}
}

.dse-checkbox-flex {
	display: flex;
	flex-direction: row-reverse;
}
