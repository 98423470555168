.highlight {
    color: $primary;
    font-weight: 700;
}

.btn {
    background: $primary;
    color: $light;
    cursor: pointer;
    display: inline-block;
    font-size: rem(14px);
    line-height: 1rem;
    padding: .8rem 1.2rem;
    text-align: center;
    text-decoration: none;
    transition: 600ms all ease-in-out;
    margin-bottom: rem($base-gap / 2);
    border: rem(1px) solid $primary;

    @include breakpoint(medium) {
        padding: rem(14px) rem(24px);
    }

    @include breakpoint(giant) {
        font-size: rem(16px);
    }

    @include breakpoint(huge) {
        padding: rem(18px) rem(34px);
    }

    &:hover, &:active, &:focus {
        background: transparent;
        color: $primary;
    }

    &.red {
        background: $secondary;
        color: $light;
        border: rem(1px) solid $secondary;

        &:hover, &:active, &:focus {
            background: transparent;
            color: $secondary;
        }
    }

    &.block {
        display: block;
        width: 100%;
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}

.clear {
    clear: both;
    float: none;
}

.clearfix {
    @include pie-clearfix;
}

.hidden {
    display: none;
}

.hide-text {
    @include hide-text();
    display: inline-block;
    overflow: hidden;
    width: 0;
}

.float-left {
    float: left;
}

img.float-left {
    margin-right: 1rem;
}

.float-right {
    float: right;
}

img.float-right {
    margin-left: 1rem;
}

.fluid {
    height: auto;
    width: 100%;
}

.non-fluid {
    width: auto!important;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.wide {
    width: 100%;
    display: block;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

/* 16:9 video resolutions */
.video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        border: none;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.lightbox-inline {

}

.lightbox-external {

}

/* default colorbox hover */
.lightbox-image {
    @extend .margin-bottom;
    display: table;
    position: relative;
    text-decoration: none;
    width: 100%;

    &[href*=qr] {
        width: auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        @extend %animated-transform;
        pointer-events: none;
    }

    &:before {
        bottom: 0;
        color: rgba($light, .75);
        content: '+';
        display: block;
        font-family: sans-serif;
        font-size: 2rem;
        height: 2em;
        line-height: 1.8;
        position: absolute;
        right: 0;
        text-align: center;
        text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, .8);
        transform: translate3d(0, 0, 0);
        width: 2em;
        z-index: 1;
    }

    &:after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &:hover, &:focus {

        &:before {
            bottom: 50%;
            font-size: 3rem;
            height: 1.5em;
            line-height: 1.5;
            right: 50%;
            transform: translate3d(50%, 50%, 0);
            width: 1.5em;
        }

        &:after {
            opacity: .5;
        }
    }
}

body {
    &:before {
        $content: 'default:' + $bp-context;
        width: str_length($content) * 6pt + $base-gap * 2;

        @each $point, $width in $breakpoints {
            $content: $content + '....' + $point + ':' + $width;

            @include breakpoint($point) {
                width: str_length($content) * 6pt + $base-gap * 2;
            }
        }

        content: '#{$content}';
        display: none !important; /* Prevent from displaying. */
    }

    &.devmode:before, &.debug:before {
        background: $alert;
        border-color: $alert;
        border-style: solid;
        border-width: 0 rem($base-gap);
        color: $light;
        display: block !important;
        font-family: Courier;
        font-size: 10pt;
        left: 0;
        line-height: 2.5;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        white-space: nowrap;
        z-index: 99999;
    }
}

// Animations
%animated-transform {
    transition: .3s;
}